<template>
  <div class="mb-2">
    <Subtitle subtitle="Equivalencias" />

    <FormAddEquivalence />
    <Separator v-if="equivalences.length > 0" />
    <FormEditEquivalence v-if="equivalences.length > 0" />

    <Empty v-else notFound="Equivalencias" />
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';

export default {
  components: {
    Subtitle: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "Subtitle" */ '@/modules/cms/components/Subtitle.vue'
      )
    ),
    Empty: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "Empty" */ '@/modules/cms/components/Empty.vue'
      )
    ),
    Separator: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "Separator" */ '@/modules/cms/components/Separator.vue'
      )
    ),
    FormAddEquivalence: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "FormAddEquivalence" */ '@/modules/cms/parts/ingredients/equivalences/FormAddEquivalence.vue'
      )
    ),
    FormEditEquivalence: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "FormEditEquivalence" */ '@/modules/cms/parts/ingredients/equivalences/FormEditEquivalence.vue'
      )
    ),
  },
  setup() {
    const equivalences = [];
    return {
      equivalences,
    };
  },
};
</script>
